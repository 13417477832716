import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes';

window.main = window.main || {};

var $htmlbody = $('html, body');
var $body = $('body');

var siteName = 'Guy Mees'
var ww = $(window).width();
var wh = $(window).height();
var dw = $(document).width();
var dh = $(document).height();

var $header 				=	$('.js-header');
var $main 					=	$('.js-main');
var $nav 					  =	$('.js-nav');
var $subNav         = $('.js-subnavigation');
var $section 				= $('.js-section');

var $btnToggleNav			= $('.js-toggle-nav');
var $btnToggleSubNav	= $('.js-toggle-subnavigation');

var $anchorLink				= $('.js-anchor-link');
var anchorLink 				=	'.js-anchor-link';
var $anchorItem				=	$('.js-anchor-item');
var anchorItem 				=	'js-anchor-item';
var clickedAnchor;

var didScroll = false;
var currentPos;
var currentItem;
var lastScrollPos = 0;
var delta = 15;

var isTouchDevice = false;

main.init = function() {

  // History.Adapter.bind(window,'statechange',function(){
	// 	var State = History.getState();
	// });

  // main.checkOnScroll($(document).scrollTop());
	// main.checkSection();

	main.initEvents();

  var pjax = new Pjax({
		cacheBust: false,
    selectors: [
      "title",
      "meta[name=description]",
      ".c-site-main"
    ]
  })

  function checkIfTouchDevice(){
      return true == ("ontouchstart" in window || window.DocumentTouch && document instanceof DocumentTouch);
  }

  if(checkIfTouchDevice() === true) {
    $body.addClass('is-touch');
    $body.addClass('is-mobile-device');
    isTouchDevice = true;
  }
  else {
    isTouchDevice = false;
  }

  $(window).on('load', function() {
    main.setLayout();
  });

  $(window).on('resize', function() {
    main.setLayout();
  });

};

main.checkSection = function() {

	// Check the url path on page load, to see if you need to scroll to a certain section.
	// For example /about should scroll to the about section immediately after entering the page.

	var urlitem,
		urltoload;

	if( window.location.hash ) {
		urlitem = window.location.hash.substring(1); //Puts hash in variable, and removes the # character
		if( $('#'+urlitem).length ) {
			main.scrollTo('#'+urlitem);
		}
	}

};

main.initEvents = function() {

	function whenContainerReady() {
		if($nav.hasClass('is-toggled')) {
			$nav.removeClass('is-toggled');
		}
	}

	document.addEventListener("pjax:success", whenContainerReady)

  $btnToggleSubNav.on('click', function(e) {
    e.preventDefault();
		console.log('toggle subnav');
    $subNav.toggleClass('is-toggled');
  });

  $btnToggleNav.on('click', function(e) {
    e.preventDefault();
    $(this).toggleClass('is-toggled');
    $nav.toggleClass('is-toggled');
  });

	$body.on('click', anchorLink, function(e) {
		e.preventDefault();
		clickedAnchor = $(this).attr('href');
		main.scrollTo($(clickedAnchor));
	});

  if(isTouchDevice == false) {
    var posX;
    var posY;
  	$('.js-floatingimage-container').mousemove(function(event) {
  		posX = event.pageX;
  		posY = event.pageY;
  		$('.js-floatingimage').css({
  			'top': posY + 'px',
  			'left': posX + 'px'
  		});
  	});
  }

};

main.updateState = function(url,type) {

	var rootstring = window.location.origin;
	var newurl = url.replace(rootstring,'');

	if (type === 'page') {

		var pageTitle = $('#page-content section').attr('data-title');
		// history.pushState({}, "page", newurl);
		document.title = pageTitle + ' – ' + siteName;

	} else if (type === 'section') {

		var pageTitle = $(url).attr('data-title');
		// history.pushState({}, "page", newurl);
		document.title = pageTitle + ' – ' + siteName;

	}

};

main.scrollTo = function(href) {

	$('html:not(:animated), body:not(:animated)').animate({
		scrollTop: $(href).position().top
	}, 550);

	$htmlbody.on('scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove', function(){
		$htmlbody.stop();
	});

};

main.setLayout = function() {

  ww = $(window).width();
  wh = $(window).height();
  dw = $(document).width();
  dh = $(document).height();

};

main.setSection = function(currentPos) {

	var offset = (ww / 100) * 3;

	$anchorItem.each(function() {
		var top = $(this).offset().top - offset,
			bottom = top + $(this).innerHeight();

		if (currentPos >= top && currentPos <= bottom) {
			$anchorItem.removeClass('is-active');
			$anchorLink.removeClass('is-active');

			$(this).addClass('is-active');
			currentItem = $(this).attr('id');
			$(anchorLink+'[href="#' + currentItem + '"]').addClass('is-active');
			main.updateState('#'+currentItem,'section');

		}
	});

};

main.checkOnScroll = function(currentPos) {

	if(currentPos <= delta) {
		currentItem = $anchorItem.first().attr('id');
	}

	if(Math.abs(lastScrollPos - currentPos) <= delta)
		return;

	//Functions to run on scroll
	main.setSection(currentPos);

	//Set last scroll pos to currentpos
	lastScrollPos = currentPos;

	//return false;

};

//On Scroll
$(document).on('scroll', function(event) {

	didScroll = true;
	main.checkOnScroll($(this).scrollTop());

});

setInterval(function(){

	if(didScroll){
		didScroll = false;
	}

},250);


main.init();
